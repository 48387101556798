<template>
  <div>
    <div class="banner">
       <warning-tip code="TAOKOULING"></warning-tip>
       <warning-tip code="GET_TAOKOULING_DETAIL"></warning-tip>
      <main-top title="淘口令" desc="" spend="TAOKOULING,GET_TAOKOULING_DETAIL" column="淘口令生成,解析淘口令"></main-top>

      <div style="display:flex;flex-wrap:wrap;justify-content: space-between;">
        <bordure-row title="淘口令生成" shadow class="detail-body">
          <!-- 搜索 -->
          <div class="multipleSearch">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="rules" ref="formInline3">
              <el-form-item label="口令链接:" prop="goodsId" style="width:100%;">
                <el-input v-model="formInline.goodsId" placeholder="请输入您要生成淘口令的链接"></el-input>
              </el-form-item>
              <el-form-item label="口令标题:" prop="searchKey" style="width:100%;">
                <el-input v-model="formInline.searchKey" placeholder="淘口令在手淘弹框显示的标题"></el-input>
              </el-form-item>
            </el-form>
            <div style="text-align:center;">
              <el-button type="primary" size="small" @click="getSearchName('formInline3')" style="width:140px;margin-top:10px;">生成淘口令
              </el-button>
              <el-button type="info" size="small" @click="reset" style="margin-left: 20px; width:140px;margin-top:10px;">重置</el-button>
            </div>
          </div>
        </bordure-row>

        <bordure-row title="解析淘口令" shadow  class="detail-body">
          <div style="padding:16px 30px;">
            <el-form :model="formAnalysis" ref="formAnalysis" :rules="Analysisrules" :inline="false" size="normal">
              <el-form-item label="淘口令" prop="password" style="width:100%;">
                <el-input v-model="formAnalysis.password" placeholder="请输入淘口令"></el-input>
              </el-form-item>
              <el-form-item>
                <div style="text-align:center;">
                  <el-button type="primary"  size="small" @click="analysisWay('formAnalysis')" style="width:140px;">解析淘口令
                  </el-button>
                </div>
              </el-form-item>
            </el-form>

            <el-table :data="detailsList" stripe size="mini">
              <el-table-column label="口令标题" width="200">
                <template slot-scope="scope">
                  <div v-if="scope.row">{{scope.row.content}}</div>
                </template>
              </el-table-column>
              <el-table-column label="口令链接">
                <template slot-scope="scope">
                  <div style="white-space: nowrap;text-overflow:ellipsis;overflow: hidden;" v-if="scope.row">
                    <el-button type="primary" size="mini" @click="copyLink(scope.row.url)">复制</el-button>
                    {{scope.row.url}}
                  </div>
                </template>
              </el-table-column>
            </el-table>


          </div>
        </bordure-row>
      </div>



      <bordure-row title="淘口令历史记录" style="margin-bottom:30px;">
        <!-- table -->
        <div class="bannerContent">
          <el-table :data="tableData" size="mini" style="border: 1px solid #e5e5e5; border-bottom: 0" :header-cell-style="{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }">
            <el-table-column header-align="center" width="300" label="淘口令" align="center">
              <template slot-scope="scope">
                <span style="margin-right: 20px">{{ scope.row.password }}</span>
                <el-button type="primary" size="mini" @click="copyLink(scope.row.password)">复制</el-button>
              </template>
            </el-table-column>
            <el-table-column header-align="center" prop="longUrl" label="口令链接"></el-table-column>
            <el-table-column header-align="center" prop="content" label="口令标题"></el-table-column>
            <el-table-column header-align="center" prop="createTime" width="200" align="center" label="创建时间">
            </el-table-column>
          </el-table>

          <!-- <div style="padding: 0 80px 100px ;text-align:center">
          <el-button type="info" plain style="width:800px">
            <div
              id="copyMy"
              style="
              width:780px;overflow:hidden;
            white-space: nowrap;
            text-overflow:ellipsis;"
            >{{value}}</div>
          </el-button>
          <el-button style="margin-left: 30px" type="success" plain @click="copyLink">点击复制链接</el-button>
        </div>-->
        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
  import {
    clearTaokouling,
    clearTaokoulingList,
    analysisTaokouling
  } from "../../request/api"; //这里是引入请求

  import Introduce from "../public/Introduce";
  export default {
    data() {
      return {
        CanYouClick: true,
        tableData: [],
        // 基本信息data
        formInline: {
          goodsId: "",
          searchKey: "",
        },
        //表单的校验规则
        rules: {
          goodsId: [{
              required: true,
              message: "请输入您要生成淘口令的链接",
              trigger: "blur",
            },
            {
              min: 5,
              message: "请输入您要生成淘口令的链接",
              trigger: "blur"
            },
          ],
          searchKey: [{
              required: true,
              message: "淘口令在手淘弹框显示的标题",
              trigger: "blur",
            },
            {
              min: 1,
              message: "淘口令在手淘弹框显示的标题",
              trigger: "blur"
            },
          ],
        },
        value: "https://item.taobao.com/item.htm?spm=a211oj.20087502.2458555970.ditem1.48af2a7bnba0PK&id=640387960875&utparam=null",

        // 解析淘口令
        formAnalysis: {
          password: ''
        },
        Analysisrules: {
          password: [{
            required: true,
            message: "请输入您的淘口令",
            trigger: "blur",
          }],
        },
        detailsList: [],
        introduce: [{
          title: "有什么作用？",
          icon: "&#xe60a;",
          content: [
            "淘口令生成工具 支持生成 优惠券链接、活动链接、淘宝/天猫商品原始链接等等该接口仅支持淘宝旗下的域名链接",
          ],
        }, ], //介绍相关数组
      };
    },
    created() {
      if (this.$store.state.token != null) {
        this.clearTaokoulingListFn();
      }
    },
    components: {
      Introduce,
    },
    methods: {
      getSearchName(formName) {
        //表单验证的方法
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.commit("alterJumpFlag", true);
            this.$store.commit("alterLoadContent", `获取生成淘口令`);

            this.CanYouClick = false; //不允许再点击搜索
            clearTaokouling({
              content: this.formInline.searchKey,
              longUrl: this.formInline.goodsId,
            }).then((data) => {
              this.clearTaokoulingListFn();
            }).finally(() => {
              this.CanYouClick = true; //允许再次搜索
              this.$store.commit("alterJumpFlag", false);
            });
          } else {
            console.log("error 失败了");
            return false;
          }
        });
      },
      clearTaokoulingListFn() {
        clearTaokoulingList().then((data) => {
          this.tableData = data.data;
        });
      },
      //实现一键复制粘贴功能
      copyLink(temp) {
        // var val = document.getElementById("copyMy");
        // window.getSelection().selectAllChildren(val);
        // document.execCommand("Copy");
        const copyUrl = document.createElement("input"); //创建一个textarea框获取需要复制的文本内容//textarea多行，input单行
        if (temp != undefined) {
          copyUrl.value = temp;
        } else {
          copyUrl.value = this.value;
        }
        document.body.appendChild(copyUrl);
        copyUrl.select();
        document.execCommand("Copy");
        document.body.removeChild(copyUrl);
        this.$message({
          message: "已复制完成，使用 Ctrl+v 可一键粘贴",
          type: "success",
        });
      },
      reset() {
        this.formInline = {
          goodsId: "",
          searchKey: "",
        };
      },

      // 解析淘口令
      analysisWay(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.detailsList = []
            this.$store.commit("alterJumpFlag", true);
            this.$store.commit("alterLoadContent", `正在解析淘口令`);
            analysisTaokouling(this.formAnalysis).then((data) => {
              let obj = {
                content: data.data.content,
                url: data.data.url
              }
              // this.detailsList[0].content = data.data.content
              // this.detailsList[0].url = data.data.url
              this.detailsList.push(obj)
               
            }).finally(() => {  
              this.$store.commit("alterJumpFlag", false);
            });

          }
        })
      }
    },
  };
</script>

<style lang="less" scoped>
  /* 查询后的内容标题样式 */
  .bannerContent {
    margin: 0 auto;
  }

  .multipleSearch {
    max-width: 800px;
    margin: 0 auto;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 16px 31px;
    box-sizing: border-box;
    margin-bottom: 20px;

    /deep/.el-form-item__content {
      width: 100%;
    }
    /deep/.el-form-item__label{
      font-size: 12px;
    }
    /deep/.el-form-item__label,
    /deep/.el-input--small,
    /deep/.el-input__inner {
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
    }

    /deep/.el-form-item__error {
      color: #f70000;
      letter-spacing: 0;
    }
  }

  .detail-body{
    margin-bottom:30px;
    width: 48%;
  }

 @media screen and (max-width: 640px){
    .detail-body{
      width: 100% !important;
    }

  }

</style>